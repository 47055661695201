import React, { useState } from "react"
import { Box, Flex, Grid, Link } from "@theme-ui/components"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"

const LanguageSwitcher = ({ dark = false }) => {
  const [show, setShow] = useState(true)

  const languagesCountry = {
    it: "Italiano",
    en: "English",
    "en-us": "English (United States)",
    "en-ca": "English (Canada)",
    fr: "Français",
    de: "Deutsch",
    es: "Español",
    pt: "Português",
    pl: "Polski",
    ru: "Русский",
  }

  return (
    <LanguageSwitcherContext.Consumer>
      {({ activeLocale, paths }) => {
        const activeLink = paths.find(x => x.locale === activeLocale)
        return (
          <Grid
            columns={[ paths.length]}
            gap={[0]}
            sx={{
              mr: [3],
              mb: [2],
              flexDirection: "rows",
              // alignItems: "flex-start",
              position: "relative",
              listStyle: "none",
              boxShadow: "default",
              borderRadius: "sm",
            }}
          >
            {paths.map((link, index) => (
              <Box key={index}>
                <Link
                  href={link.value}
                  sx={{
                    whiteSpace: "nowrap",
                    padding: 2,
                    display: "block",
                    minWidth: "42px",
                    textAlign: "center",
                    textDecoration: "none",
                    textTransform: "uppercase",
                    border: "1px solid",
                    mr: ["-1px"],
                    borderColor: dark
                      ? activeLocale === link.locale
                        ? "dark!important"
                        : "lightGrey"
                      : activeLocale === link.locale
                      ? "white!important"
                      : "lighten",
                    color: dark
                      ? activeLocale === link.locale
                        ? "dark!important"
                        : "lightGrey"
                      : activeLocale === link.locale
                      ? "white!important"
                      : "lighten",
                    ":hover": {
                      borderColor: "white!important",
                      color: `white!important`,
                      color: dark
                        ? activeLocale === link.locale
                          ? "dark!important"
                          : "lightGrey"
                        : activeLocale === link.locale
                        ? "white!important"
                        : "lighten",
                      borderColor: dark
                        ? activeLocale === link.locale
                          ? "dark!important"
                          : "lightGrey"
                        : activeLocale === link.locale
                        ? "white!important"
                        : "lighten",
                    },
                  }}
                >
                  {link.locale}
                </Link>
              </Box>
            ))}
          </Grid>
        )
      }}
    </LanguageSwitcherContext.Consumer>
  )
}

export default LanguageSwitcher
